import React from 'react'
import PropTypes from 'prop-types'

const HTMLContent = ({ content, className }) => (
  <div
    className={`o-rte ${className || ``}`}
    dangerouslySetInnerHTML={{ __html: content }}
  />
)

HTMLContent.propTypes = {
  content: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default HTMLContent
