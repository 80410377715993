import React from 'react'
import GoogleMapReact from 'google-map-react'
import classNames from 'classnames'

import { styles } from './styles'

import { ReactComponent as PinSVG } from 'assets/img/svg/pin.svg'

import { TwitterIcon, InstagramIcon } from 'Icons'

const socialIcons = [{
  name: 'Twitter',
  icon: <TwitterIcon />,
  url: 'https://twitter.com/stakpod'
}, {
  name: 'Instagram',
  icon: <InstagramIcon />,
  url: 'https://instagram.com/stakpod'
}]

export const MapMarker = ({ text }) => (
  <div className='c-map-marker'>
    <PinSVG className='c-map-marker__pin' />
  </div>
)

const MapAddress = ({ address }) => {
  return (
    <address>
      { address.split(',').map((part, partIndex) => {
        return (
          <p key={partIndex} className='u-margin-bottom-none'>{part}</p>
        )
      })}
    </address>
  )
}

const GoogleMap = ({ title, lat, lng, address }) => {
  return (
    <div className='c-map'>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: `AIzaSyArbHul1QVYJ8BbVWQA09xGkM7OwznLWkA `
        }}
        defaultCenter={{
          lat: lat,
          lng: lng
        }}
        defaultZoom={14}
        options={{
          styles: styles,
          disableDefaultUI: true
        }}
      >
        <MapMarker
          lat={lat}
          lng={lng}
          text={title}
        />
      </GoogleMapReact>
      <div className='c-map-overlay'>
        <div className='c-map-overlay__content'>
          <p className='c-map-overlay__header'>{title}</p>
          <MapAddress address={address} />
          <div>
            { socialIcons.map((socialIcon, socialIconIndex) => {
              return (
                <a
                  key={socialIconIndex}
                  href={socialIcon.url}
                  target='_blank'
                  rel='noopener noreferrer'
                  className={classNames('c-social-icon', `c-social-icon--${socialIcon.name.toLowerCase()}`, { 'u-margin-left-none': (socialIconIndex === 0) })}
                  aria-label={socialIcon.name}
                >
                  { socialIcon.icon }
                </a>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default GoogleMap
