import React from 'react'

const HeroTitle = ({ text }) => (
  <h1
    className='c-hero__title u-ta-c u-margin-bottom-none'
  >
    {text}
  </h1>
)

export default HeroTitle
