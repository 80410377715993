import React from 'react'

import View from './HeroView'

const Hero = ({ title, leader, meta }) => (
  <View
    title={title}
    leader={leader}
    meta={meta}
  />
)

export default Hero
