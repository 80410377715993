import React from 'react'
import PropTypes from 'prop-types'

const Content = ({ content, className }) => (
  <div className={`o-rte ${className || ``}`}>
    <p>{content}</p>
  </div>
)

Content.propTypes = {
  content: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default Content
