import React, { Fragment } from 'react'

import Hero from 'Hero'
import GoogleMap from 'GoogleMap'
import { HTMLContent } from 'Content'

const ContactPageView = ({ title, content, location }) => {
  return (
    <Fragment>
      <Hero
        title={title}
      />
      <div className='c-contact'>
        <div className='o-container'>
          <div className='c-contact__body'>
            <HTMLContent content={content} className='o-rte--show-br' />
          </div>
          <div className='c-contact__map'>
            <GoogleMap {...location} />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default ContactPageView
