import React from 'react'

import { HTMLContent } from 'Content'

import HeroTitle from './HeroTitle'
import HeroLeader from './HeroLeader'
import HeroMeta from './HeroMeta'

const HeroView = ({ title, leader, meta }) => (
  <div
    className='c-hero'
  >
    <div
      className='o-container--thin'
    >
      { title && <HeroTitle text={title} /> }
      { leader && <HeroLeader content={leader} contentComponent={HTMLContent} /> }
      { meta && <HeroMeta items={meta} /> }
    </div>
  </div>
)

export default HeroView
