import React from 'react'
import { graphql } from 'gatsby'

import ContactPageTemplate from 'ContactPage'

const ContactPage = ({ data }) => {
  const { markdownRemark: page } = data

  const {
    excerpt,
    html,
    frontmatter: {
      title,
      location
    }
  } = page

  return (
    <ContactPageTemplate
      title={title}
      excerpt={excerpt}
      content={html}
      location={location}
    />
  )
}

export default ContactPage

export const PageQuery = graphql`
  query {
    markdownRemark(
      frontmatter: {
        templateKey: {
          eq: "pages/contact"
        }
      }
    ) {
      id
      html
      excerpt
      frontmatter {
        title
        location {
          address
          lat
          lng
          title
        }
      }
    }
  }
`
