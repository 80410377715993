import React from 'react'

import Content from 'Content'

const HeroLeader = ({ content, contentComponent }) => {
  const SectionContent = contentComponent || Content

  return (
    <SectionContent
      content={content}
      className='c-hero__leader e-h2'
    />
  )
}

export default HeroLeader
