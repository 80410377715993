import React from 'react'
import PropTypes from 'prop-types'

import HTMLContentView from './HTMLContentView'
import ContentView from './ContentView'

export const HTMLContent = ({ content, className }) => (
  <HTMLContentView
    content={content}
    className={className}
  />
)

HTMLContent.propTypes = {
  content: PropTypes.node.isRequired,
  className: PropTypes.string
}

const Content = ({ content, className }) => (
  <ContentView
    content={content}
    className={className}
  />
)

Content.propTypes = {
  content: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default Content
