import React from 'react'

import SEO from 'SEO'
import Layout from 'Layout'
import View from './ContactPageView'

const ContactPage = ({ title, content, excerpt, location }) => {
  return (
    <Layout>
      <SEO
        title={title}
        description={excerpt}
      />
      <View
        title={title}
        content={content}
        location={location}
      />
    </Layout>
  )
}

export default ContactPage
